import pick from "lodash/pick";
import firebase from "lib/firebase";

// Format fields within a Document
export const formatFields = fields => {
  for (const key in fields) {
    // Convert Firestore Timestamp to JavaScript Date Object
    if (fields[key] instanceof firebase.firestore.Timestamp) {
      fields[key] = fields[key].toDate();
    }
  }

  return fields;
};

// Format multiple Documents from a Query
export const formatDocs = (docs, keys = null, country = null) => {
  // Country Exclusion
  const results = country
    ? docs.filter(doc => doc.data().countries[country])
    : docs;

  return results.map(doc => {
    const data = doc.data();

    let fields = keys ? pick(data, keys) : data;
    fields = formatFields(fields);

    return { id: doc.id, ...fields };
  });
};
