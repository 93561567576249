import React from "react";
import styled from "styled-components";

const ScreenReader = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const ScreenReaderIndex = ({ children }) => {
  return <ScreenReader>{children}</ScreenReader>;
};

export default ScreenReaderIndex;
