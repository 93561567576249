import pick from "lodash/pick";
import { db } from "lib/firebase";
import { formatDocs, formatFields } from "lib/models/format";

export const getByCategory = async (category, keys) => {
  const query = await db
    .collection("products")
    .where("category", "==", category)
    .where("archived", "==", false)
    .orderBy("name", "asc")
    .get();

  return formatDocs(query.docs, keys);
};

export const getByID = async (id, keys = null) => {
  if (!id) return null;

  const doc = await db
    .collection("products")
    .doc(id)
    .get();

  if (!doc.exists) return null;

  const data = doc.data();

  let fields = keys ? pick(data, keys) : data;
  fields = formatFields(fields);

  return { id: doc.id, ...fields };
};
