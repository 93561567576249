import pick from "lodash/pick";
import sampleSize from "lodash/sampleSize";
import { db } from "lib/firebase";
import { formatDocs, formatFields } from "lib/models/format";

export const getAll = async (
  keys,
  country,
  limit = null,
  status = ["live"]
) => {
  let query = db.collection("quizzes");

  if (status) query = query.where("status", "in", status);

  if (limit) query = query.limit(limit);

  query = await query.get();

  return formatDocs(query.docs, keys, country);
};

export const getPopularQuizzes = async (keys, country, limit = 6) => {
  let query = db
    .collection("quizzes")
    .where("status", "==", "live")
    .orderBy("completed", "desc")
    .limit(30);

  query = await query.get();

  const docs = sampleSize(query.docs, limit);

  return formatDocs(docs, keys, country);
};

export const getById = async (id, keys = null) => {
  const doc = await db.collection("quizzes").doc(id).get();

  if (!doc.exists) return null;

  const data = doc.data();

  let fields = keys ? pick(data, keys) : data;
  fields = formatFields(fields);

  return { id: doc.id, ...fields };
};

export const getBySlug = async (slug, keys = null, country = null) => {
  const query = await db
    .collection("quizzes")
    .where("slug", "==", slug)
    .limit(1)
    .get();

  if (query.empty) return null;

  const doc = query.docs[0];

  const data = doc.data();

  if (data.status === "archive") return null;

  if (country && !data.countries[country]) return null;

  let fields = keys ? pick(data, keys) : data;
  fields = formatFields(fields);

  return { id: doc.id, ...fields };
};

export const searchQuizzes = async (searchTerm, country) => {
  const term = searchTerm.toLowerCase().trim();

  const query = await db
    .collection("quizzes")
    .where("searchTags", "array-contains", term)
    .where("status", "==", "live")
    .get();

  return formatDocs(query.docs, ["name", "slug", "image"], country);
};

export const getAnswers = async (id, keys) => {
  const query = await db
    .collection("quizzes")
    .doc(id)
    .collection("answers")
    .get();

  return formatDocs(query.docs, keys);
};
