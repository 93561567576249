import get from "lodash/get";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import round from "lodash/round";
import { db } from "lib/firebase";
import { formatDocs, formatFields } from "lib/models/format";
import { getById as getQuiz } from "./Quiz";
import { getByID as getProduct } from "./Product";

const getRelatedQuizzes = async docs => {
  const quizIds = uniq(docs.map(doc => doc.quiz));
  const quizzes = [];

  for (const id of quizIds) {
    const quiz = await getQuiz(id, ["name", "slug"]);
    quizzes.push(quiz);
  }

  return docs.map(doc => ({
    ...doc,
    quiz: quizzes.find(q => q.id === doc.quiz)
  }));
};

const getBestChoiceProduct = async (docs, country) => {
  const productIds = uniq(docs.map(doc => doc.products.best));
  const products = [];

  for (const id of productIds) {
    const product = await getProduct(id, [
      "name",
      "image",
      `prices.${country}`
    ]);
    products.push(product);
  }

  return docs.map(doc => ({
    ...doc,
    products: {
      ...doc.products,
      best: products.find(p => p.id === doc.products.best)
    }
  }));
};

export const getAll = async (user, keys, country) => {
  const query = await db
    .collection("quizzes-taken")
    .where("user", "==", user)
    .orderBy("endTime", "desc")
    .get();

  // Only gather past results that offered products
  let docs = query.docs.filter(doc => doc.data().products);

  // Format docs
  docs = formatDocs(docs, keys);

  // Fetch related quizzes
  docs = await getRelatedQuizzes(docs);

  // Fetch Best Choice Product
  docs = await getBestChoiceProduct(docs, country);

  return docs;
};

export const getByID = async (id, keys = null) => {
  const doc = await db
    .collection("quizzes-taken")
    .doc(id)
    .get();

  if (!doc.exists) return null;

  const data = doc.data();

  let fields = keys ? pick(data, keys) : data;
  fields = formatFields(fields);

  return { id: doc.id, ...fields };
};

const incrementQuizStat = async (quizId, type) => {
  // Increment started count on quiz file
  const quizRef = db.collection("quizzes").doc(quizId);
  const quiz = await quizRef.get();
  const data = quiz.data();
  const count = get(data, type, 0);
  await quizRef.update({ [type]: count + 1 });
};

const incrementQuizCompleted = async (quizId, startTime, endTime) => {
  // Increment started count on quiz file
  const quizRef = db.collection("quizzes").doc(quizId);
  const quiz = await quizRef.get();
  const data = quiz.data();
  const started = get(data, "started", 0);
  const completed = get(data, "completed", 0);
  const oldAvgTime = get(data, "averageTime", 0);

  // Get time taken in seconds
  const newTime = endTime.getTime() / 1000 - startTime.getTime() / 1000;

  // Calculate new average Time = Old Average + (New Value - Old Average) / New Size
  const averageTime = round(oldAvgTime + (newTime - oldAvgTime) / started, 2);

  await quizRef.update({ completed: completed + 1, averageTime });
};

export const startQuiz = async (user, quiz) => {
  const doc = await db.collection("quizzes-taken").add({
    quiz,
    user,
    startTime: new Date(),
    endTime: null,
    completed: false,
    converted: false
  });

  // Update Quiz Document
  await incrementQuizStat(quiz, "started");

  return doc.id;
};

export const setCompleted = async (id, fields = {}) => {
  const query = await db
    .collection("quizzes-taken")
    .doc(id)
    .update({ completed: true, ...fields });

  // Update Quiz Document
  const { quiz, startTime } = await getByID(id, ["quiz", "startTime"]);
  await incrementQuizCompleted(quiz, startTime, fields.endTime);

  return query;
};

export const setConverted = async id => {
  const query = await db
    .collection("quizzes-taken")
    .doc(id)
    .update({ converted: true });

  // Update Quiz Document
  const { quiz } = await getByID(id, ["quiz"]);
  await incrementQuizStat(quiz, "converted");

  return query;
};

export const update = async (id, fields) => {
  return await db
    .collection("quizzes-taken")
    .doc(id)
    .update(fields);
};
