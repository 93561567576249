import React from "react";
import Link from "next/link";
import styled from "styled-components";
import { darken } from "polished";

const Button = styled.button`
  display: inline-block;
  padding: 1.5rem;
  min-width: 18rem;
  text-align: center;
  font-weight: bold;
  border-radius: 0.5rem;
  color: ${(props) => (props.secondary ? "#333" : "#fff")};
  background-color: ${(props) =>
    props.secondary ? "#fff" : props.theme.palette.primary};
  transition: background-color 0.2s ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) =>
        props.secondary
          ? darken(0.1, "#fff")
          : darken(0.1, props.theme.palette.primary)}};
    }
  }

  @media (min-width: 480px) {
    padding: 2rem;
    font-size: 1.8rem;
  }
`;

const ButtonIndex = ({ children, href, as, ...props }) => {
  if (href) {
    return (
      <Link href={href} as={as} passHref>
        <Button as="a" {...props}>
          {children}
        </Button>
      </Link>
    );
  }

  return <Button {...props}>{children}</Button>;
};

export default ButtonIndex;
